import styled, {css} from 'styled-components';
import {media} from '../../../styles/utils';
import {colors} from '../../../styles/variables';
import {HeadlineStyledH1} from '../../Elements/Headline/styles';
import Link from '../../Elements/Link/Link';
import Button from '../../Elements/Button/Button';

const LoginFormStyles = css`
	box-sizing: border-box;
	position: relative;
	z-index: 1;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;

	${media.sm`
		width: 80vw;
	`};

	${media.lg`
		width: 60vw;
	`};
`;

export const LoginStyled = styled.div`
	${LoginFormStyles};
`;

export const SidebarLoginStyled = styled.div`
	${LoginFormStyles};
`;

export const FormFieldContainer = styled.div`
	width: 100%;
`;

export const LoginContentStyled = styled.div`
	box-sizing: border-box;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 1;
`;

export const HeadlineStyled = styled(HeadlineStyledH1)`
	color: ${colors.black};
	text-align: center;
`;

export const LabelStyled = styled.label`
	display: flex;
	justify-content: center;
	align-items: center;
	.ap-text {
		margin: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	span {
		font-size: 1.3em;
	}
	margin: 1em;
`;
export const LinkStyled = styled(Link)`
	color: ${colors.black};
	border-bottom: 3px solid ${colors.carrot};
	transition: color 0.3s ease;

	&:hover,
	&:active,
	&:focus {
		color: ${colors.carrot};
	}
`;
export const ButtonStyled = styled(Button)`
	margin: 0;
`;

export const RememberCheckboxContainerStyled = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;
