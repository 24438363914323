import React from 'react';
import PropTypes from 'prop-types';
import {LabelStyled} from '../styles';

const FormLabel = ({text}) =>
	// don't render when text is null, undefined, or an empty string
	text ? <LabelStyled>{text}</LabelStyled> : null;

FormLabel.propTypes = {
	text: PropTypes.string
};

FormLabel.defaultProps = {
	text: ''
};

export default FormLabel;
