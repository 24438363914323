import styled from 'styled-components';
import {sizes} from '../../../../styles/variables';
import {withUnit} from '../../../../styles/utils';

export const RowStyled = styled.div`
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	flex: 0 1 auto;
	margin: 0 -0.5em ${withUnit(sizes.distance.base / 2)} -0.5em;
	flex-wrap: wrap;
`;
