import PropTypes from 'prop-types';

export const standardPropTypesForFields = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	myError: PropTypes.string,
	required: PropTypes.bool,
	validate: PropTypes.func
};

export const standardDefaultPropsForFields = {
	label: '',
	myError: null,
	required: false,
	validate: null
};
