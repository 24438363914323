import styled, {css} from 'styled-components';
import {alertColors, colors, sizes} from '../../../styles/variables';
import {withUnit} from '../../../styles/utils';
import {Icon} from '../Icon/Icon';

/*
======================
 Field validation
======================
 */
export const InputMessageGlobalStyled = styled.div`
	font-weight: 700;
	font-size: 0.7em;
	color: ${colors.base};
	display: flex;
	align-items: center;
	margin-top: 0.5em;

	// Status
	${props => (props.error ? `color: ${alertColors.error.full};` : null)};
	${props => (props.warn ? `color: ${alertColors.warning.full};` : null)};
	${props => (props.info ? `color: ${alertColors.info.full};` : null)};
`;

export const InputMessageIconGlobalStyled = styled(Icon)`
	border-radius: 100%;
	background-color: ${colors.snow};
	color: ${colors.white};
	width: 21px;
	height: 21px;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-right: 0.5em;
	flex: none;

	// Status
	${props => (props.error ? `background-color: ${alertColors.error.full};` : null)};
	${props => (props.warn ? `background-color: ${alertColors.warning.full};` : null)};
	${props => (props.info ? `background-color: ${alertColors.info.full};` : null)};
`;

// common css for all fields
export const commonCssForInput = css`
	background-color: ${({hasError}) => (hasError ? 'pink' : colors.snow)};
`;

export const LabelStyled = styled.label`
	display: block;
	font-size: 0.6em;
	padding: 0 ${withUnit(sizes.distance.base)} ${withUnit(sizes.distance.base / 4)} ${withUnit(sizes.distance.base)};
	text-align: left;
	text-transform: uppercase;
	font-weight: 800;
`;
