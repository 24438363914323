import React from 'react';
import PropTypes from 'prop-types';
import {RowStyled} from './styles';

const FormRow = props => {
	const {children} = props;
	return <RowStyled>{children}</RowStyled>;
};

FormRow.propTypes = {
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

FormRow.defaultProps = {
	children: null
};

/** @component */
export default FormRow;
