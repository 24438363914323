import styled from 'styled-components';
import {alertColors, colors, sizes} from '../../../../styles/variables';
import {withUnit} from '../../../../styles/utils';

export const InputStyled = styled.input`
	box-sizing: border-box;
	width: 100%;
	height: ${sizes.forms.inputHeight};
	border-radius: ${sizes.borderRadius};
	background-color: ${colors.snow};
	border-width: 2px;
	border-style: solid;
	border-color: ${props => (props.hasError ? alertColors.error.full : `${colors.snow}`)};
	outline: none;
	padding: 0 ${withUnit(sizes.distance.base)};

	&:focus {
		border: 2px solid ${alertColors.focus.full};
	}
`;

export const TextareaStyled = styled.textarea`
	box-sizing: border-box;
	width: 100%;
	height: 6rem;
	resize: none;
	outline: none;
	padding: ${withUnit(sizes.distance.base / 2)} ${withUnit(sizes.distance.base)};
	background-color: ${colors.snow};
	border-width: 2px;
	border-style: solid;
	border-color: ${props => (props.hasError ? alertColors.error.full : `${colors.snow}`)};

	&:focus {
		border: 2px solid ${alertColors.focus.full};
	}
`;
