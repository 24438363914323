import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/Layout/Layout';

import Section from '../components/Layout/Section/Section';
import Meta, {appendSeoTitleSuffix} from '../components/Layout/Meta';
import {PageTemplateStyled} from '../templates/styles';
import Reset from '../components/Container/Login/PasswordReset';

const ResetPage = ({location}) => {
	const params = new URLSearchParams(location.search);
	const userId = params.get('userId');
	const code = params.get('code');
	return (
		<>
			<Meta title={appendSeoTitleSuffix('Setze dein neues Passwort')} />
			<Layout location={location}>
				<PageTemplateStyled>
					<Section showPadding>
						<Reset userId={userId} code={code} />
					</Section>
				</PageTemplateStyled>
			</Layout>
		</>
	);
};

ResetPage.propTypes = {
	location: PropTypes.shape({pathname: PropTypes.string, search: PropTypes.string}).isRequired,
};

export default ResetPage;
