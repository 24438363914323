import React from 'react';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {LoginStyled, LoginContentStyled, FormFieldContainer, HeadlineStyled, ButtonStyled} from './styles';

import {PasswordInput} from '../../Elements/Form/InputAndTextarea/PasswordInput';

import {setNewPassword} from '../../../state/actions/userManagement';
import Text from '../../Elements/Text/Text';
import {forms, mailRegExp} from '../../../assets/language/de';
import {withAsterisk} from '../../../utils/formHelpers';
import FormRow from '../../Elements/Form/FormRow/FormRow';
import Column from '../../Layout/Grid/Column';
import Section from '../../Layout/Section/Section';

const {validationMessages} = forms.Register;
const {text, label, buttons} = forms.PasswordReset;

const ResetSchema = Yup.object().shape({
	Password1: Yup.string()
		.min(8, 'Das Passwort ist zu kurz')
		.max(50, 'Das Passwort ist zu lang')
		.required('Bitte gib dein neues Passwort ein')
		.test('regex', validationMessages.password.ifFalseFormat, (val) => mailRegExp.test(val)),
	Password2: Yup.string()
		.oneOf([Yup.ref('Password1'), null], 'Die Passwörter stimmen nicht überein')
		.required('Bitte gib dein neues Passwort ein'),
});

const Reset = ({userId, code, handleSetNew}) => {
	const handleSubmit = (data, {resetForm}) => {
		const credentials = {userId, code, password: data.Password1};
		handleSetNew(credentials);
		resetForm(Reset.initialValues);
	};

	return (
		<LoginStyled className="ap-login">
			<LoginContentStyled>
				<HeadlineStyled>{text.headline}</HeadlineStyled>
				<Text content={text.intro} />
				<FormFieldContainer>
					<Formik
						initialValues={{Password1: '', Password2: ''}}
						validationSchema={ResetSchema}
						onSubmit={(values, {resetForm}) => handleSubmit(values, {resetForm})}
						render={() => (
							<Form>
								<Section center>
									<FormRow>
										<Column md={6}>
											<PasswordInput label={withAsterisk(label.newPassword)} name="Password1" />
										</Column>
									</FormRow>
									<FormRow>
										<Column md={6}>
											<PasswordInput
												label={withAsterisk(label.repeatPassword)}
												name="Password2"
											/>
										</Column>
									</FormRow>
									<FormRow>
										<Column md={6}>
											<ButtonStyled text={buttons.submit} theme="candy" type="submit" />
										</Column>
									</FormRow>
								</Section>
							</Form>
						)}
					/>
				</FormFieldContainer>
			</LoginContentStyled>
		</LoginStyled>
	);
};

const mapStateToProps = (state) => ({
	user: state.userManagement.user,
});
const mapDispatchToProps = (dispatch) => ({
	handleSetNew: (data) => dispatch(setNewPassword(data)),
});

Reset.propTypes = {
	user: PropTypes.shape({
		UserId: PropTypes.string,
	}),
	code: PropTypes.string.isRequired,
	userId: PropTypes.string.isRequired,
	handleSetNew: PropTypes.func,
};

Reset.defaultProps = {
	user: null,
	handleSetNew: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reset);
